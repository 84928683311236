import * as React from 'react';

import type { HeadProps, PageProps } from 'gatsby';

import { Link } from 'stile-shared/src/components/2020/common/Link';
import { Divider, Prose, Section } from 'stile-shared/src/components/2020/common/Primitives';

import { PageLayout } from 'templates/2020/PageLayout';
import { SEO } from 'templates/2020/SEO';

import { CtaBlock } from 'components/blocks/CtaBlock';

export function Head(props: HeadProps) {
  return (
    <SEO
      title="Terms of Use"
      description="By subscribing to our services, you agree with our terms of use. Read our terms of use here!"
      slug={props.location.pathname}
    />
  );
}

function TermsPage(props: PageProps) {
  return (
    <PageLayout {...props}>
      <Section>
        <Prose>
          <h1>Terms of use</h1>
          <p>
            These Terms of Use (this “<a href="#agreement-dfn">Agreement</a>”) apply to your
            installation, access or use of the Services (as defined herein) or any of the content
            made available via the Services provided by [Stile Education Inc.], its subsidiaries or
            affiliates (“<a href="#stile-dfn">Stile</a>,” “<a href="#we-dfn">we</a>,” “
            <a href="#us-dfn">us</a>,” and “<a href="#our-dfn">our</a>”).
          </p>
          <p>
            BY USING THE SERVICES, YOU AGREE TO THE TERMS OF THIS AGREEMENT; IF YOU DO NOT AGREE, DO
            NOT USE THE SERVICES. STILE RESERVES THE RIGHT, AT ITS SOLE AND ABSOLUTE DISCRETION, TO
            CHANGE, MODIFY, ADD OR REMOVE PORTIONS OF THIS AGREEMENT, AT ANY TIME. IT IS YOUR
            RESPONSIBILITY TO CHECK THE TERMS OF THIS AGREEMENT PERIODICALLY FOR CHANGES. YOUR
            CONTINUED USE OF THE SERVICES FOLLOWING THE POSTING OF CHANGES WILL MEAN THAT YOU ACCEPT
            AND AGREE TO THE CHANGES.
          </p>
          <h2>1. Definitions</h2>
          <p>
            “<dfn id="agreement-dfn">Agreement</dfn>” has the meaning set forth in the opening
            paragraph hereto.
          </p>
          <p>
            “<dfn id="business-day-dfn">Business Day</dfn>” means any day (except a Saturday,
            Sunday, public holiday in Melbourne (Australia), the State of California (United States)
            or Federal public holiday) on which banks are generally open for non-automated business
            in the State of California (United States) and Melbourne (Australia).
          </p>
          <p>
            “<dfn id="commencement-date-dfn">Commencement Date</dfn>” means the date the Services
            are to commence as set out in the Subscription Agreement.
          </p>
          <p>
            “<dfn id="confidential-information-dfn">Confidential Information</dfn>” means any
            information disclosed by Stile to the Customer, whether before or after the date of the
            Subscription Agreement, that (1) is in written, graphic, machine readable or other
            tangible form and is marked “Confidential”, “Proprietary” or in some other manner to
            indicate its confidential nature, (2) if not marked, the Customer should reasonably
            understand to be the confidential or trade secret information of Stile, or (3) is oral
            information disclosed by Stile to the Customer, provided that such information is
            designated as confidential at the time of disclosure.
          </p>
          <p>
            “<dfn id="customer-dfn">Customer</dfn>,” “<dfn>you</dfn>” and “<dfn>your</dfn>” mean the
            purchaser of the Services identified in the Subscription Agreement.
          </p>
          <p>
            “<dfn id="customer-data-dfn">Customer Data</dfn>” means data of any kind in whatever
            form (including text images, audio and video) contributed, linked to or otherwise made
            available through the Services by or from a User.
          </p>
          <p>
            “<dfn id="dispute-dfn">Dispute</dfn>” has the meaning set forth in clause 16.12.
          </p>
          <p>
            “<dfn id="feedback-dfn">Feedback</dfn>” has the meaning set forth in clause 6(h).
          </p>
          <p>
            “<dfn id="fees-dfn">Fees</dfn>” means the fees payable for the Services as set out in
            the Subscription Agreement and, as applicable, as modified in accordance with this
            Agreement.
          </p>
          <p>
            “<dfn id="ferpa-dfn">FERPA</dfn>” has the meaning set forth in clause 10(d).
          </p>
          <p>
            “<dfn id="force-majeure-dfn">Force Majeure</dfn>” means any circumstance beyond a
            Party’s reasonable control, including acts of God, flood, fire, earthquake or explosion,
            war, terrorism, invasion, riot or other civil unrest, embargoes or blockades in effect
            on or after the date of this Agreement, national or regional emergency, pandemic,
            strikes, labor stoppages or slowdowns or other industrial disturbances, passage of law
            or any action taken by a governmental or public authority, including imposing an
            embargo, export or import restriction, quota or other restriction or prohibition or any
            complete or partial government shutdown, or national or regional shortage of adequate
            power or telecommunications or transportation.
          </p>
          <p>
            “<dfn id="information-dfn">Information</dfn>” has the meaning set forth in clause
            13(b)(i).
          </p>
          <p>
            “<dfn id="initial-term-dfn">Initial Term</dfn>” means the one-, three- or five-year
            subscription term option chosen by the Customer in the Subscription Agreement.
          </p>
          <section>
            “<dfn id="intellectual-property-rights-dfn">Intellectual Property Rights</dfn>” means:
            <ol>
              <li>
                any patent, trademark, copyright, design or other design right, and any
                corresponding property or right, whether registered or unregistered, as applicable,
                under the laws of the United States or any other jurisdiction in the world
                (including any applications for registration of the foregoing rights);
              </li>
              <li>
                any right under the laws of the United States or any other jurisdiction in the world
                to apply for the grant or registration of a patent, trademark, copyright, design or
                any corresponding property right; and
              </li>
              <li>
                any rights and the benefit of any obligation owed in respect of an invention,
                discovery, trade secret, know-how, concept, data, database, information, process,
                methodology, formula or confidential information.
              </li>
            </ol>
          </section>
          <p>
            “<dfn id="library-dfn">Library</dfn>” means the functionality in the Services permitting
            teachers to share subjects and lessons including Customer Data and User Content.
          </p>
          <p>
            “<dfn id="license-dfn">License</dfn>” has the meaning set forth in clause 2.2.
          </p>
          <p>
            “<dfn id="notice-dfn">Notice</dfn>” has the meaning set forth in clause 16.1.
          </p>
          <p>
            “<dfn id="parties-dfn">Parties</dfn>” means Stile and the Customer, together.
          </p>
          <p>
            “<dfn id="party-dfn">Party</dfn>” means Stile or the Customer.
          </p>
          <p>
            “<dfn id="person-dfn">Person</dfn>” means any individual, corporation, estate,
            partnership, joint venture, association, joint stock company, limited liability company,
            trust, unincorporated association, or government or any agency or political subdivision
            thereof, or any other entity of whatever nature.
          </p>
          <p>
            “<dfn id="personal-information-dfn">Personal Information</dfn>” means any means any
            information that identifies, relates to, describes, is reasonably capable of being
            associated with, or could reasonably be linked, directly or indirectly, with a
            particular natural person or is protected as personal information or personal data under
            applicable laws, including but not limited to the Children’s Online Privacy Protection
            Act, the Family Educational Rights and Privacy Act (FERPA) and the California Privacy
            Protection Act, which is processed by Stile pursuant to or in connection with this
            Agreement.
          </p>
          <p>
            “<dfn id="privacy-policy-dfn">Privacy Policy</dfn>” means Stile’s Privacy Policy, which
            can be accessed at{' '}
            <Link url="/other/privacy">stileeducation.com/us/other/privacy/</Link>.
          </p>
          <p>
            “<dfn id="renewal-term-dfn">Renewal Term</dfn>” has the meaning set forth in clause
            14.1(b).
          </p>
          <p>
            “<dfn id="scheduled-maintenance-dfn">Scheduled Maintenance</dfn>” has the meaning set
            forth in clause 4(b).
          </p>
          <p>
            “<dfn id="services-dfn">Services</dfn>” means the provision of access to Stile’s
            interactive e-learning tools, curriculum and content to you via the Stile Platform as
            set forth in more detail in the Subscription Agreement.
          </p>
          <p>
            “<dfn id="stile-dfn">Stile</dfn>”, “<dfn id="we-dfn">we</dfn>”, “
            <dfn id="us-dfn">us</dfn>” and “<dfn id="our-dfn">our</dfn>” has the meaning set forth
            in the opening paragraph hereto.
          </p>
          <p>
            “<dfn id="stile-library-dfn">Stile Library</dfn>” means the content feature within the
            Stile Platform where we publish lesson materials and other content as part of the
            Services.
          </p>
          <p>
            “<dfn id="stile-ipr-dfn">Stile IPR</dfn>” means all Intellectual Property Rights
            subsisting in the Stile Platform, Stile Library or any Stile website, and includes
            without limitation all Intellectual Property Rights (including software source code and
            any information stored in the Stile Library and Stile Toolset), exclusive of any
            Customer Data or User Content.
          </p>
          <p>
            “<dfn id="stile-platform-dfn">Stile Platform</dfn>” means our cloud-based, online,
            interactive e-learning tool and curriculum builder.
          </p>
          <p>
            “<dfn id="stile-toolset-dfn">Stile Toolset</dfn>” means the elements and functionality
            used to deliver the Services including, without limitation: websites, graphics, layout,
            text, images, audio and/or video, designs, logos, domain names, trade names and marks,
            software, source code, “look and feel” and the manner in which these elements are
            arranged.
          </p>
          <p>
            “<dfn id="subscription-agreement-dfn">Subscription Agreement</dfn>” has the meaning set
            forth in clause 2.1.
          </p>
          <p>
            “<dfn id="subscription-documentation-dfn">Subscription Documentation</dfn>” has the
            meaning set forth in clause 2.1.
          </p>
          <p>
            “<dfn id="term-dfn">Term</dfn>” has the meaning set forth in clause 14.1(b).
          </p>
          <p>
            “<dfn id="third-party-services-dfn">Third-Party Services</dfn>” has the meaning set
            forth in clause 5.
          </p>
          <p>
            “<dfn id="user-dfn">User</dfn>” means a teacher engaged by your training or educational
            organization, a student of your training or educational organization and any other
            Person authorized to use the Services.
          </p>
          <p>
            “<dfn id="user-account-dfn">User Account</dfn>” means a user name and logon information
            required for a User to access the Services.
          </p>
          <p>
            “<dfn id="user-content-dfn">User Content</dfn>” has the meaning set forth in clause
            6(d).
          </p>

          <h2>2. License and Access</h2>
          <h3>2.1 Subscription Agreement</h3>

          <p>
            This Agreement shall be read in conjunction with the Subscription Agreement to be
            entered into by Stile and Customer (the “
            <a href="#subscription-agreement-dfn">Subscription Agreement</a>”), the Privacy Policy,
            any schedules, appendixes, annexes, and exhibits hereto and thereto, delivered pursuant
            to the terms hereof and thereof (collectively, the “
            <a href="#subscription-documentation-dfn">Subscription Documentation</a>”).
          </p>

          <h3>2.2 License</h3>
          <p>
            Subject to the terms of and provided Customer is in compliance with this Agreement and
            the Subscription Documentation, and during the Term, Stile grants to the Customer a
            non-exclusive, non-transferable, revocable license (the “
            <a href="#license-dfn">License</a>”) to access and use the Services for the purposes of
            preparing and delivering training and education activities, developing User Content and
            sharing Customer Data and User Content in accordance with the terms of this Agreement
            and the Subscription Documentation. All rights not expressly granted to you are reserved
            by us.
          </p>

          <h3>2.3 Access and Security</h3>
          <p>
            Stile shall provide the Customer with information required to access and use the
            Services, including user name or password details. The Customer is responsible for
            designating and authorizing the persons who are Users and for ensuring that they comply
            with this Agreement. The Customer must not disclose any such information to any other
            Person or store it in a manner that would reasonably allow any such other to obtain
            access to it. The Customer is responsible for all activities under its Account, whether
            authorized or not, including any acts or omissions of any Customer User.
          </p>

          <h3>2.4 Provision of Information</h3>
          <p>
            The Customer shall supply Stile with complete, accurate and up-to-date information when
            entering into the Subscription Agreement, and shall periodically update this
            information, as necessary, to ensure that it remains accurate.
          </p>

          <h3>2.5 Limited Use</h3>
          <p>
            Unless Stile agrees otherwise in writing, the Customer is provided with access to the
            Services only for its educational or internal business use and otherwise in accordance
            with the License granted in clause 2.2 and this Agreement. The Customer shall not, and
            shall cause the Users not to, use the Services for any other purpose without the prior
            written consent of Stile.
          </p>

          <h3>2.6 Ongoing Development</h3>
          <p>
            The Customer acknowledges that Stile may regularly update the Services, including by
            releasing new features and removing or improving existing features.
          </p>

          <h3>2.7 Expiration or Termination</h3>
          <p>
            Subject to the terms and conditions of this Agreement and the Subscription
            Documentation, upon expiry or termination of Customer’s subscription to the Services as
            set forth in the Subscription Agreement, the License will be revoked and, from that
            date, the Customer must immediately cease and must ensure the Users immediately cease
            accessing and otherwise using the Services.
          </p>

          <h2>3. Payment and Invoicing</h2>

          <h3>3.1 Payment of Fees</h3>
          <ol type="a">
            <li>
              The Customer must pay Stile the Fees in the manner and on the dates specified in the
              Subscription Agreement. Unless otherwise agreed by the Parties in writing, Fees are
              payable annually in advance of the Commencement Date. Thereafter, Fees are payable
              annually in advance of each anniversary of the Commencement date within the Initial
              Term. After the expiration of the Initial Term, Fees are payable annually in advance
              of the first date of each Renewal Term. Except as otherwise specified in the
              Subscription Agreement, Fees are non-refundable.
            </li>

            <li>
              Timely payment of the Fees and any other amounts due and payable under this Agreement
              is an essential term of this Agreement.
            </li>

            <li>
              Unless otherwise expressly stated in this Agreement or agreed to by the Parties in
              writing, all Fees and other amounts due and payable or to be provided under or in
              accordance with this Agreement or the Subscription Agreement are exclusive of tax.
              Where applicable, the Customer must pay, in addition to the Fees, any tax levied or
              imposed upon Stile as a result of the provision of the Services and in accordance with
              applicable law, including but not limited to sales, use and excise taxes, and other
              similar taxes, duties and charges of any kind imposed by any federal, state or local
              government or regulatory authority, but excluding tax on Stile’s income.
            </li>

            <li>
              Fees will be fixed for the duration of the Initial Term, and Stile may increase Fees
              at the commencement of any Renewal Term. Stile will notify the Customer of any
              increase in Fees in advance of the commencement of the Renewal Term to which such
              increase will apply.
            </li>
          </ol>

          <h3>3.2 Overdue Amounts</h3>

          <p>
            If any Fees or other amounts due and payable to Stile under this Agreement have not been
            paid by the Customer by the due date for such Fees or other amounts, Stile may, in its
            sole and absolute discretion:
          </p>
          <ol type="a">
            <li>
              suspend performance of the Subscription Agreement and this Agreement (in part or in
              full) until the Fees or other amounts outstanding are paid in full; or
            </li>

            <li>terminate the Subscription Agreement and this Agreement.</li>
          </ol>

          <h3>3.3 Additional Rights</h3>

          <p>The exercise by Stile of any of its rights under clause 3.2 does not affect:</p>
          <ol type="a">
            <li>
              the Customer’s obligation to pay any Fees or other amounts due or payable under this
              Agreement or the Subscription Agreement; or
            </li>

            <li>
              any other rights or remedies Stile may have in relation to any failure by the Customer
              to pay any Fees or other amounts due and payable under this Agreement, the
              Subscription Agreement or any other agreement between Stile and the Customer.
            </li>
          </ol>

          <h2>4. Availability, Outages and Interruptions</h2>
          <ol type="a">
            <li>
              Stile may undertake maintenance activities which are in its reasonable opinion
              necessary to properly provide, or to facilitate improvements to, or maintenance of,
              the Services as more fully described in clauses 4(b) and (c) below.
            </li>

            <li>
              Stile will use commercially reasonable efforts to minimize the need for maintenance
              work (the “<a href="#scheduled-maintenance-dfn">Scheduled Maintenance</a>”) that may
              disrupt the provision of the Services to the Customer. Stile will use commercially
              reasonable efforts to minimize any disruption to the provision of the Services to the
              Customer caused by such Scheduled Maintenance and, if reasonably practicable, may, but
              will not be required to, provide details of the nature of the Scheduled Maintenance.
            </li>

            <li>
              Notwithstanding this clause 4 or otherwise, Stile reserves the right to undertake
              emergency maintenance in respect of the Stile Platform or otherwise without any notice
              to the Customer. If reasonably practicable, Stile may, but will not be required to,
              notify the Customer prior to undertaking such emergency maintenance.
            </li>

            <li>
              Stile reserves the right to monitor access to and use of the Services from time to
              time including to ensure compliance with this Agreement and the Subscription
              Agreement, including any usage restrictions hereunder and thereunder.
            </li>
          </ol>

          <h2>5. Links to Third-Party Services</h2>

          <p>
            The Stile Toolset may provide links to websites or services operated by third parties (“
            <a href="#third-party-services-dfn">Third-Party Services</a>”). Because we have no
            control over Third-Party Services, we are not responsible for the availability of those
            Third-Party Services and do not endorse and are not responsible or liable for any
            privacy practices, content, advertising, services, products or other materials on or
            available from such Third-Party Services.
          </p>

          <h2>6. Intellectual Property Rights</h2>

          <ol type="a">
            <li>
              The Customer acknowledges and agrees that Stile is, and will remain, the exclusive
              owner and holder of the Stile IPR, and at no time during or after the Term will the
              Stile IPR transfer to, assign to or otherwise vest in the Customer.
            </li>

            <li>During and after the Term:</li>

            <ol type="i">
              <li>
                the Customer must not use, challenge, interfere with or otherwise impede Stile’s
                ownership and use of the Stile IPR;
              </li>
              <li>
                the Customer must not use or attempt to use, license or attempt to license or
                otherwise deal with, transfer, assign or grant a license in relation to the Stile
                IPR;
              </li>
              <li>
                the Customer must not engage in the infringement of the Stile IPR, including by
                hacking, reverse engineering, decompiling, disassembling, trying to derive the
                source code of, or otherwise duplicating or reproducing the Stile IPR;
              </li>
              <li>
                the Customer must not remove, alter or obscure any proprietary rights notices
                contained in or affixed to the Services;
              </li>
              <li>
                with the exception of content development activities allowable as part of the
                Services, the Customer must not modify or make derivative works based upon the
                Services or the Stile Toolset; and
              </li>
              <li>
                the Customer must not “copy”, “frame” or “mirror”, any Stile Toolset or any other
                part of the Services on any server or wireless or Internet-based device.
              </li>
            </ol>

            <li>
              Subject to clause 10(f), Stile acknowledges and agrees that the Customer is, and will
              remain, the exclusive owner and holder of the Customer Data, and at no time during or
              after the Term will the Customer Data transfer to, be assigned to or otherwise vest in
              Stile.
            </li>

            <li>
              Stile has tools that allow you to publish lesson-related content, including subjects,
              lessons and activities, to other Stile Platform Users via the Library (“
              <a href="#user-content-dfn">User Content</a>”). When another User downloads your User
              Content from the Library, you grant to such User a limited license to access and use
              the User Content within their own lessons. While User Content can be removed from the
              Library, a User may continue to access and use any downloaded copies of the User
              Content after such removal. You acknowledge that only lesson-related content is
              shareable as User Content, and you agree to ensure no student information, submissions
              or results are included within User Content made available on the Library.
            </li>

            <li>
              You are responsible for User Content that you post. Under no circumstances will we be
              liable in any way for any User Content. This means that you, not Stile, are entirely
              responsible for all User Content that you publish. Because we do not control the User
              Content posted on or through the Library, we cannot and do not warrant or guarantee
              the truthfulness, integrity suitability or quality of that User Content.
            </li>

            <li>
              All trademarks displayed on the Stile Platform and forming any part of the Services
              are trademarks of their respective owners. Nothing contained in this Agreement should
              be construed as granting any license or right of use with respect to any trademark
              displayed on the Stile Platform without the express written permission of the relevant
              owner.
            </li>

            <li>
              The Customer shall immediately notify Stile in writing of any actual, suspected or
              anticipated infringement of the Stile IPR or breach of this clause 6.
            </li>

            <li>
              You may provide suggestions, comments or other feedback (each, “
              <a href="#feedback-dfn">Feedback</a>”) to Stile regarding the Services. Stile may
              freely use, disclose, reproduce, license, distribute or exploit Feedback without
              restriction and without attribution or compensation to you. You agree that your
              provision of Feedback does not give you any Intellectual Property Rights or any other
              right, title or interest in or to the Stile IPR. Stile has no obligation to
              incorporate Feedback into any product or service, and you have no obligation to
              provide Feedback. You acknowledge that any Feedback you provide is entirely voluntary
              and agree that we will own all right, title and interest in the Feedback, and the
              exclusive, worldwide, perpetual, fully transferable and sublicensable, royalty-free
              and fully paid up right to use the feedback for any purposes including as a part of
              the Services, without any obligation to seek your approval or pay you any
              compensation. You have no expectation or right of confidentiality in any Feedback.
            </li>

            <li>
              Stile may collect data in connection with the Customer’s use of the Services, such as
              log files and technical data. Notwithstanding anything to the contrary in this
              Agreement, any such collected data is owned by Stile, and Stile may use and exploit it
              in any manner without restriction.
            </li>
          </ol>

          <h2>7. Users</h2>

          <h3>7.1 Maximum Users</h3>
          <ol type="a">
            <li>
              You acknowledge that the Fees are set in accordance with the maximum number of Users
              permitted to access the Services under the Subscription Agreement.
            </li>
            <li>
              We will monitor the number of Users to whom you grant User Accounts. You must ensure
              that each User Account is used only by one User and not shared with any other Person.
            </li>
            <li>
              In the event that you obtain User Accounts for more than the maximum number of Users
              permitted under the Subscription Agreement, you shall immediately notify us of the
              additional User Accounts, and we reserve the right at any time to issue an invoice for
              an amount that we reasonably determine to be due in connection with those additional
              User Accounts, which amount will be calculated on the same terms as the Fees
              associated with the existing User Accounts. Invoices issued under this clause 7.1(c)
              are due within [thirty (30)] days of the date of invoice.
            </li>
          </ol>

          <h3>7.2 Student Number Variation</h3>
          <p>
            In the event that student enrolment numbers at the Customer’s school change, Stile may,
            upon written request by the Customer and in Stile’s sole and absolute discretion,
            increase or reduce the number of student Users the Customer has purchased (within 10% of
            the Customer’s total number of student Users), within the first two (2) months of the
            start of a new school year at the Customer’s school. Stile will agree to make such an
            increase or reduction in writing and will credit or debit the Customer’s account
            accordingly.
          </p>

          <h3>7.3 User Account Usage</h3>
          <p>
            A User is deemed to have accessed or used the Services when logging in and answering any
            question, or accessing any of Stile’s course materials provided in the Services.
          </p>

          <h3>7.4 User Account Obligations</h3>
          <p>The Customer must ensure that:</p>
          <ol type="a">
            <li>
              each User complies with the applicable Customer obligations under this Agreement; and
            </li>
            <li>
              it implements a password policy in respect of access to the Services which reasonably
              ensures that User passwords meet or exceed industry standard password strength
              requirements.
            </li>
          </ol>

          <h2>8. Customer Obligations; Representations and Warranties</h2>
          <ol type="a">
            <li>
              At its own cost, the Customer must ensure that its Users, as applicable:
              <ol type="i">
                <li>
                  provide and maintain any equipment and ancillary services necessary to connect to,
                  access and otherwise use the Services, including, without limitation, modems,
                  hardware, servers, software, operating systems, networking and web servers;
                </li>

                <li>
                  use the Services for its or their own business purposes in accordance with the
                  scope of the License in clause 2.2 and, in relation to the Customer, provide
                  educational services to its students and otherwise not for the benefit of any
                  third party, unless agreed otherwise by Stile in writing;
                </li>

                <li>
                  not upload, either using the Services or otherwise, any content that contains any
                  computer viruses, worms, trojan horses, spyware or any other potentially damaging
                  technology or software;
                </li>

                <li>
                  not redistribute, copy, publish, sell, alter, modify, tamper with, reverse
                  engineer, decompile or disassemble the Services or any content generated by Stile
                  unless agreed otherwise by Stile in writing;
                </li>

                <li>
                  not use any materials or information created by or provided in connection with the
                  Services, or use the Services, for any purpose other than for its or their own use
                  in accordance with the scope of License in clause 2.2;
                </li>

                <li>
                  not use the Services to transmit unsolicited advertising or spam to other Users;
                </li>

                <li>
                  not use the Services to distribute content to any Person or entity that is not a
                  User unless otherwise allowable as part of the Services;
                </li>

                <li>
                  not use the Services to store or distribute illegal, harmful or malicious material
                  or link to websites containing such material;
                </li>

                <li>
                  not use the Services in a way that breaches any law or violates any Person’s
                  Intellectual Property Rights;
                </li>

                <li>
                  not use the Services to bully, intimidate or harass any User or any third party;
                </li>

                <li>
                  not create or upload Customer Data or User Content that could be considered hate
                  speech or that incites violence;
                </li>

                <li>
                  not attempt to bypass security controls or otherwise gain unauthorized access to
                  resources within the Services;
                </li>

                <li>
                  not attempt to disrupt, degrade, impair or violate the integrity or security of
                  the Services, including, without limitation, by executing any form of network
                  monitoring;
                </li>

                <li>
                  not attempt to interfere with or disrupt the proper operation of the Services in
                  any way;
                </li>

                <li>
                  not take any action that imposes an unreasonable or disproportionately large load
                  on Stile’s infrastructure;
                </li>

                <li>
                  have appropriate policies and procedures in place relating to unauthorized access
                  or misuse of Services; and
                </li>

                <li>
                  maintain the confidentiality of any login name and password allocated to it or
                  them and not disclose such login name and password to any third party.
                </li>
              </ol>
            </li>

            <li>
              The Customer represents and warrants to Stile that, as of the date hereof and at all
              times during the Term:
              <ol type="i">
                <li>
                  it has, the legal right and authority to fully perform all of its obligations as
                  contemplated under this Agreement and the Subscription Documentation;
                </li>

                <li>
                  it will use the Services only for lawful purposes and in accordance with this
                  Agreement and the Subscription Documentation; and
                </li>

                <li>
                  to the best of its knowledge, any software, data, equipment or other materials to
                  be provided by it to Stile or employed by it in its use of or receipt of the
                  Services does not infringe any Intellectual Property Rights of any third party, is
                  not obscene or defamatory of any Person and does not violate any applicable laws.
                </li>
              </ol>
            </li>
          </ol>

          <h2>9. Export Restrictions</h2>

          <p>
            It is expressly understood and acknowledged that the Customer may not remove or export
            from the United States or allow the export or re-export of the Services or anything
            related thereto, or any direct product thereof in violation of any restrictions, laws or
            regulations of the United States Department of Commerce, the United States Department of
            Treasury Office of Foreign Assets Control, any other United States or foreign agency or
            authority or any other applicable laws.
          </p>

          <h2>10. Customer Data and Privacy</h2>

          <ol type="a">
            <li>
              The Customer is responsible for ensuring that all the Customer Data and other
              materials made available to Stile, including all alterations to the Customer Data and
              such other materials provided by the Customer, are accurate and complete and comply
              with all applicable laws.
            </li>

            <li>
              Without limiting the generality of the foregoing, the Customer warrants to Stile that
              it will not disclose to Stile or submit via the Services any information that is (i)
              “personal health information” as defined under the Health Insurance Portability and
              Accountability Act of the United States of America, (ii) government-issued
              identification numbers, including Social Security numbers, driver’s license numbers
              and other state or national issued identification numbers, except for student
              identification numbers, (iii) financial account information, (iv) payment card data,
              (v) biometric information, or (vi) “sensitive” personal data, as defined under
              Directive 95/46/EC of the European Parliament and any national laws adopted pursuant
              thereto or substantially similar to such laws or “sensitive personal information” as
              defined under the California Privacy Rights Act.
            </li>

            <li>
              The Privacy Policy is incorporated herein by reference and forms part of this
              Agreement.
            </li>

            <li>
              The Customer is responsible for providing all notices and obtaining any necessary
              consents from Users (and if applicable their parent or legal guardian) to allow for
              the use, disclosure and transfer of Personal Information in the course of such Users
              accessing the Services and to permit the processing of such Personal Information by
              Stile to provide the Services. If Customer Data is subject to the Family Educational
              Rights and Privacy Act (“<a href="#ferpa-dfn">FERPA</a>”), the Customer is responsible
              for ensuring that its provisions of Personal Information complies with FERPA,
              including obtaining all necessary consents and permissions from an individual’s parent
              or legal guardian.
            </li>

            <li>
              The Customer represents and warrants that it has the necessary rights, licenses,
              clearances or consents needed to submit the Customer Data to Stile.
            </li>

            <li>
              The Customer grants a non-exclusive, royalty-free, worldwide license to Stile for the
              Term to copy, store, transmit, display, access, reproduce, distribute and otherwise
              use and display Customer Data to the extent reasonably necessary to provide and
              maintain the Services for the Customer and for such longer period as may be required
              to comply with applicable law.
            </li>

            <li>
              The Parties agrees that Stile will be acting as Customer’s service provider as that
              term is used in the California Consumer Privacy Act (“CCPA”) solely with respect to
              Customer Data that is personal information subject to the CCPA. With respect to such
              Customer Data, Stile will not: (i) sell the personal information or (ii) collect,
              retain, use, or disclose the personal information for any purpose other than for the
              specific purpose of performing the Services, including collecting, retaining, using,
              or disclosing personal information for any commercial purpose other than providing the
              Services specified in the Agreement unless otherwise permitted under the CCPA or its
              implementing regulations. Customer agrees that as a service provider Stile is not
              responsible for responding directly to any requests from consumers relating to their
              personal information collected by Stile as a service provider and will forward such
              requests to Customer for a response.
            </li>

            <li>
              Without limiting any other term of this Agreement:
              <ol type="i">
                <li>
                  Stile agrees to comply with all applicable laws in handling, using and disclosing
                  the Customer Data. If Customer Data is subject to the FERPA, the parties agree
                  that Stile is acting as a “school official” on behalf of Customer.
                </li>

                <li>
                  Stile must maintain appropriate data security systems, practices and policies and
                  must take all reasonable steps necessary to ensure that all Personal Information
                  held in connection with this Agreement is secure and stored or recorded accurately
                  and is protected against:
                  <ol type="a">
                    <li>corruption, interference and loss;</li>

                    <li>
                      unauthorized access, use, modification, processing, disclosure or other
                      misuse; and
                    </li>

                    <li>access other than by authorized Stile personnel.</li>
                  </ol>
                </li>

                <li>
                  The Customer may request that Stile remove or destroy Customer Data held by Stile
                  at any time during or subsequent to the Term, provided that Stile may charge a
                  reasonable fee for this service for time and materials expended. Stile retains
                  sole and absolute discretion as to the removal of Customer Data no less than 14
                  days after the expiry of the Agreement.
                </li>

                <li>
                  You acknowledge that even when you request that Customer Data is deleted, and we
                  remove it from the Services, it may persist in backups for a reasonable period of
                  time.
                </li>
              </ol>
            </li>
          </ol>

          <h2>11. Confidential Information</h2>
          <ol type="a">
            <li>
              The Customer acknowledges and agrees that any and all Confidential Information
              provided to it by or on behalf of Stile shall be deemed strictly confidential and the
              Customer will hold Confidential Information in confidence. The Customer shall not: (i)
              reproduce Confidential Information in any format or use Confidential Information for
              any purpose whatsoever other than as necessary to exercise its rights and perform its
              obligations under this Agreement; or (ii) disclose Confidential Information to any
              third party without the prior written consent of Stile. In the event that the Customer
              is required to disclose any Confidential Information (x) pursuant to any applicable
              law, rule or regulation, (y) pursuant to any subpoena, order, civil investigative
              demand or similar demand or upon the request of any court, regulatory or supervisory
              authority, self-regulatory organization, contract market, arbitrator or tribunal
              having jurisdiction over the Parties, or (z) for evidentiary purposes in any action,
              proceeding or arbitration to which the Customer is a party, the Customer shall provide
              Stile with prompt prior written notice of such request in order to enable Stile to
              seek an appropriate protective order or other remedy (and if Stile seeks such an order
              or remedy, the Customer will provide such cooperation as Stile shall reasonably
              request), to consult with the Customer with respect to Stile taking steps to resist or
              narrow the scope of such request or legal process, or to waive compliance, in whole or
              in part, with the terms of this Agreement. In the event that such protective order or
              other remedy is not obtained, or Stile waives compliance, in whole or in part, with
              the terms of this Agreement, the Customer will disclose only that portion of the
              Confidential Information advised in writing by counsel to be legally required to be
              disclosed and will use the Customer’s best efforts to ensure that all Confidential
              Information so disclosed will be accorded confidential treatment.
            </li>

            <li>
              The Customer agrees that no ownership of Confidential Information is conveyed to the
              Customer. Subject to the foregoing, without Stile’s prior written approval, the
              Customer shall not directly or indirectly disclose to anyone the existence or terms of
              this Agreement.
            </li>

            <li>
              The Customer agrees that it will not disclose to Stile any proprietary information or
              trade secrets which the Customer is prohibited from so disclosing pursuant to the
              terms of any confidentiality or similar agreement or obligation between the Customer
              and any other Person. The Customer shall not improperly use or disclose any
              proprietary information or trade secrets of any former or concurrent employer of the
              Customer or any other third party. The Customer also agrees that the Customer will not
              bring or transfer onto Stile’s technology systems any such proprietary information or
              trade secrets belonging to any third party unless disclosure to, and use by, Stile has
              been consented to in writing by such third party and a copy of such writing has been
              provided to Stile.
            </li>
          </ol>

          <h2>12. Consent</h2>

          <p>
            The Customer acknowledges that Stile and its third party service providers may
            communicate with the Customer for the limited purpose of:
          </p>

          <ol type="a">
            <li>facilitating the Customer’s access to and use of the Services;</li>

            <li>providing the Customer with news and information about the Services;</li>

            <li>responding to any specific request the Customer makes of Stile;</li>

            <li>
              administering and managing the Services, as well as performing administrative and
              operational tasks (including risk management, systems development and testing, and
              market or customer research);
            </li>

            <li>
              notifying the Customer of any changes to, enhancements of, Scheduled Maintenance to or
              outages that may affect the Services;
            </li>

            <li>
              providing the Customer with the opportunity to ‘opt-in’ to receiving promotional
              material from time to time from Stile or its services providers; and
            </li>

            <li>
              providing the Customer with the opportunity to ‘opt-in’ and provide its consent to
              participate in enhancements to or new features of the Services, through which Stile
              may use information that is posted to or uploaded by the Customer for the purposes of
              enhancing the Customer’s use of the Services.
            </li>
          </ol>

          <h2>13. Limitation of Liability; Indemnity</h2>
          <ol type="a">
            <li>
              If Stile’s performance of its obligations under this Agreement is for any reason
              affected or delayed:
              <ol type="i">
                <li>
                  by the failure of the Customer to comply with its obligations under this
                  Agreement;
                </li>

                <li>due to the acts or omissions of the Customer or any User;</li>

                <li>
                  by any factors beyond Stile’s reasonable control including Force Majeure; or
                </li>

                <li>
                  due to incorrect access to or use of the Services by the Customer or any User,
                </li>
              </ol>
              then Stile’s obligations shall be reduced to the extent such events impact the access
              to or use of the Services, and the time for the performance of Stile’s obligations
              under this Agreement shall be extended by a reasonable period having regard to such
              failures, acts or omissions.
            </li>

            <li>
              The Customer agrees that:
              <ol type="i">
                <li>
                  Stile makes no representation or warranty regarding the accuracy or completeness
                  of any data or information contained in the Services (the “
                  <a href="#information-dfn">Information</a>”);
                </li>

                <li>
                  the Information does not purport to be a complete database of all information in
                  Stile’s possession or control;
                </li>

                <li>
                  Stile will not be liable to the Customer for any loss, damage, cost or expense
                  (direct or indirect) howsoever arising from reliance upon or use by the Customer
                  of any Information provided, or any failure of Stile to provide complete or
                  accurate Information; and
                </li>

                <li>
                  Stile will not be liable to the Customer for any loss or corruption of the
                  Customer Data for any reason including our negligence and the use of Customer Data
                  by third parties when shared using the Library.
                </li>
              </ol>
            </li>

            <li>
              Subject to Section 16 in all respects, neither Party is liable to the other Party for
              any indirect, incidental, special, exemplary or consequential loss or damage, loss of
              profits or anticipated profits, loss of revenue, economic loss, loss of business
              opportunity, damage to goodwill, loss of data, deletion or corruption of
              electronically or digitally stored information or loss or damage resulting from wasted
              management time irrespective of whether:
            </li>
            <ol type="i">
              <li>
                the loss or damage is caused by or relates to breach of contract, statute, tort
                (including negligence) or otherwise;
              </li>

              <li>the possibility of such loss or damage was foreseeable; or</li>

              <li>
                Stile or any other Person was previously notified of the possibility of the loss or
                damage.
              </li>
            </ol>
            <li>
              Each Party’s total maximum aggregate financial liability to the other Party under or
              in connection with this Agreement or any other agreement between the Parties, whether
              such claim arises in contract or in tort or otherwise, shall not exceed the amount of
              the total Fees paid by the Customer in the twelve (12) month period prior to the cause
              of action.
            </li>

            <li>The financial cap in clause 13(d) does not apply to:</li>
            <ol type="i">
              <li>breaches of clauses 6 or 10;</li>

              <li>
                fraud, willful misconduct or negligence by the first Party, its employees,
                contractors, suppliers, agents, subcontractors or service providers; or
              </li>

              <li>the extent that liability cannot be limited by law.</li>
            </ol>

            <li>
              Subject to this clause 13, reconstruction of data lost or damaged due to Stile’s
              direct acts or omissions in the course of supplying the Services will be at Stile’s
              cost provided that Stile’s sole liability to the extent permitted by law will be to
              restore that data from the most recent backup copy.
            </li>

            <li>
              The Customer shall defend, indemnify and hold harmless Stile and its affiliates and
              their respective managers, officers, directors, employees, agents, successors, and
              assigns from and against all losses, damages, liabilities, deficiencies, actions,
              judgments, interest, awards, penalties, fines, costs or expenses of whatever kind
              (including reasonable legal fees) arising out of or resulting from:
            </li>
            <ol type="i">
              <li>
                any breach of a representation, warranty or obligation under this Agreement or the
                Subscription Documentation by the Customer or a User;
              </li>

              <li>
                any breach of any applicable law, in connection with the Customer’s use of the
                Services;
              </li>

              <li>
                all and any claims whatsoever and howsoever arising made by any third party in
                connection with or arising out of the Customer’s access or use of the Services,
                Customer Data or User Content; and
              </li>

              <li>
                any failure by the Customer to obtain a consent necessary for Stile to provide the
                Services.
              </li>
            </ol>
          </ol>

          <h2>14. Term and Termination</h2>

          <h3>14.1 Term and Renewal</h3>
          <ol type="a">
            <li>
              This Agreement commences on the Commencement Date and will continue for the Initial
              Term unless earlier terminated in accordance with this clause 14. Subscriptions are
              based on the school calendar year and typically run from August 1 to July 31.
            </li>

            <li>
              At the end of the Initial Term, and upon each anniversary of the expiry of the Initial
              Term, this Agreement will be automatically renewed by a period of twelve (12) months
              (each period a “<a href="#renewal-term">Renewal Term</a>” and together with the
              Initial Term, the “<a href="#term-dfn">Term</a>”), unless the Customer provides [sixty
              (60)] days’ prior written notice to Stile that it does not intend that the Agreement
              be renewed.
            </li>
          </ol>

          <h3>14.2 Termination</h3>
          <ol type="a">
            <li>
              Either Party may terminate this Agreement immediately, effective on receipt of written
              notice by the other Party, if the other Party:
              <ol type="i">
                <li>
                  fails to comply with a material term of this Agreement and does not remedy that
                  default or breach within seven (7) days of receiving written notice to do so; or
                </li>

                <li>
                  fails to comply with a material term of this Agreement which is not capable of
                  being remedied.
                </li>
              </ol>
            </li>

            <li>
              Stile may terminate this Agreement or suspend the Customer’s access to the Services
              immediately, effective on receipt of written notice by the Customer, if the Customer:
              <ol type="i">
                <li>is in breach of any obligations under this Agreement; or</li>

                <li>
                  fails to pay Stile any Fees or other amounts due and payable under this Agreement
                  and the Subscription Agreement within seven (7) days of receiving written notice
                  demanding payment.
                </li>
              </ol>
            </li>

            <li>
              The Customer may terminate this Agreement for any reason or no reason, effective on
              receipt of written notice by Stile, within the first thirty (30) days of the
              Commencement Date.
            </li>
          </ol>

          <h3>14.3 Effect of Expiry or Termination</h3>
          <ol type="a">
            <li>
              Upon expiry or termination of this Agreement:
              <ol type="i">
                <li>Stile will immediately cease the provision of the Services;</li>

                <li>
                  The Customer shall promptly (i) deliver to Stile all tangible documents and other
                  media, including any copies, containing, reflecting, incorporating or based on the
                  Confidential Information; (ii) permanently erase all Confidential Information from
                  the Customer’s computer systems; and (iii) upon Stile’s request, certify to Stile
                  compliance with this clause 14.3(a)(ii) in writing.
                </li>

                <li>
                  Stile will issue to the Customer a final tax invoice for all Fees due, and Stile
                  will not refund any Fees to the Customer for any Services provided up to the date
                  of termination;
                </li>

                <li>
                  each Party shall pay to the other Party all amounts owed by it in connection with
                  this Agreement, including under any indemnities; and
                </li>

                <li>the License will be immediately revoked in accordance with clause 2.8.</li>
              </ol>
            </li>

            <li>
              Termination of this Agreement will not affect the obligations, rights or remedies of
              either Party under this Agreement which have accrued as of the date of termination
              (including without limitation any obligation of the Customer to pay the Fees in
              respect of the period prior to the date of termination).
            </li>
          </ol>

          <h3>14.4 Data Preservation</h3>

          <p>
            Unless required by applicable law we will not delete any Customer Data during any period
            of restricted access to, or suspension of, the Services.
          </p>

          <h2>15. Force Majeure</h2>

          <ol type="a">
            <li>
              Neither Party shall be liable for any delay or failure to perform its obligations
              under this Agreement to the extent that such delay or failure is due to Force Majeure.
            </li>

            <li>
              Any Party who is, by reason of Force Majeure, unable to perform any obligation or
              condition required by this Agreement to be performed shall notify the other Party as
              soon as possible, with the notice to contain details of the circumstances giving rise
              to the event of Force Majeure.
            </li>
          </ol>

          <h2>16. Miscellaneous</h2>

          <h3>16.1 Notices</h3>

          <p>
            Any notice, communication, consent or other document authorized or required to be given
            or served under this Agreement (each, a “<a href="#notice-dfn">Notice</a>”):
          </p>

          <ol type="a">
            <li>
              must be in writing, signed by or on behalf of the sender and addressed to the address
              or electronic address of the recipient shown in the Subscription Agreement (or to the
              address or electronic address last provided in writing by the recipient to the
              sender); and
            </li>

            <li>
              will be deemed to have been duly given or made:
              <ol type="i">
                <li>
                  in the case of delivery in Person or by post, when delivered at the correct
                  address;
                </li>
                <li>
                  in the case of delivery by email, as soon as the sender’s information system
                  transmits the email to the recipient’s email box,
                </li>
                but if delivery or transmission (i) does not occur on a Business Day or (ii) occurs
                later than 5:00 p.m. (local time of the recipient) on a Business Day, it will be
                deemed to have occurred upon the commencement of the next Business Day. In the case
                of delivery by email, if the sender receives a message sent by the recipient’s
                information system advising the sender that the recipient has not received the
                email, the Notice will be deemed to have been duly given or made only if the sender,
                within ten (10) Business Days of the delivery by email, delivers to the recipient a
                tangible copy of the Notice with end-to-end tracking and all fees paid.
              </ol>
            </li>
          </ol>
          <h3>16.2 Entire Agreement; Construction and Headings</h3>

          <p>
            This Agreement and the other Subscription Documentation set forth the entire agreement
            and understanding of the Parties with respect to the subject matter of this Agreement
            and supersede all prior agreements, promises, covenants, arrangements, communications,
            representations or warranties, whether oral or written, by any officer, employee or
            representative of any Party. In the event of a conflict between this Agreement and any
            other Subscription Document, this Agreement shall prevail. This Agreement shall be
            deemed drafted equally by each Party. Its language shall be construed as a whole and
            according to its fair meaning. Stile shall have final authority to interpret and
            construe this Agreement and the Subscription Documentation and to make any and all
            determinations under them, and its decision shall be binding and conclusive upon
            Customer in respect of any questions arising under this Agreement or the Subscription
            Documentation. Any presumption or principle that the language is to be construed against
            any Party shall not apply. The headings contained in this Agreement are for purposes of
            convenience only and shall not affect the meaning or interpretation of this Agreement.
            Defined terms in the singular include the plural, and defined terms in the plural
            include the singular.
          </p>

          <h3>16.3 Assignment</h3>
          <ol type="a">
            <li>
              The Customer shall not assign this Agreement or any of its rights and obligations
              under this Agreement, whether voluntarily, involuntarily, by operation of law or
              otherwise, without the prior written consent of Stile. Any purported assignment in
              violation of this clause 16.3 will be null and void.
            </li>

            <li>
              Stile may assign this Agreement or any of its rights and obligations under this
              Agreement without the prior written consent of the Customer.
            </li>
          </ol>

          <h3>16.4 Waiver</h3>

          <p>
            No right under this Agreement shall be deemed to be waived except by notice in writing
            signed by each Party. A waiver made by a Party will not prejudice its rights in respect
            of any subsequent breach of the Agreement by the other Party. No failure or delay on the
            part of either Party in exercising any right, power or remedy hereunder will operate as
            a waiver thereof, nor will any single or partial exercise of any such right, power or
            remedy preclude any other or further exercise thereof or the exercise of any other
            right, power or remedy.
          </p>

          <h3>16.5 Further Assurances</h3>

          <p>
            Each Party must do all things necessary to give full effect to this Agreement and the
            transactions contemplated by this Agreement.
          </p>

          <h3>16.6 Amendment</h3>

          <p>This Agreement may be amended only by the written agreement of the Parties.</p>

          <h3>16.7 Severability</h3>

          <p>
            If any provision of this Agreement is held invalid, unenforceable or illegal for any
            reason, the Agreement shall remain otherwise in full force, and the invalid,
            unenforceable or illegal provision shall be reformed to the minimum extent necessary to
            make it valid, enforceable and legal.
          </p>

          <h3>16.8 Counterpart Signatures; Digital Signatures</h3>

          <p>
            This Agreement may be executed in counterparts and, when so executed, such counterparts
            shall constitute a single instrument binding upon the Parties notwithstanding the fact
            that the Parties are not signatory to the same counterpart. Signatures to this
            Agreement, any amendment hereof and any update, notice or waiver given hereunder,
            executed and transmitted by electronic mail (or by copies of physically signed documents
            exchanged via email attachments in PDF format or equivalent) shall be valid and
            effective to bind the Party so signing.
          </p>

          <h3>16.9 Survival</h3>

          <p>
            Clauses 2, 3, 6, 8, 10, 11, 13, 14.3, 15, this clause 16 and any other clauses that by
            their nature are intended to survive expiry or termination of this Agreement (including
            any clauses which are necessary for the interpretation of those clauses), survive expiry
            or termination of this Agreement.
          </p>

          <h3>16.10 Governing Law</h3>

          <p>
            This Agreement (and any claim or controversy arising out of or relating to this
            Agreement) is governed by and construed in accordance with the laws of the State of
            California without giving effect to any choice or conflict of law provision or rule that
            would cause the application of the laws of any jurisdiction other than the State of
            California. Except to the extent a Dispute is not subject to arbitration as set forth in
            clause 16.12, Customer agrees that the exclusive jurisdiction and venue for any dispute
            arising out or relating to this Agreement or the Services shall be a court of competent
            jurisdiction located in Los Angeles County, California. Customer consents to the
            personal jurisdiction of such courts and waives an objection based on the inconvenience
            of such courts.
          </p>

          <h3>16.11 Waiver of Jury Trial</h3>

          <p>
            EACH PARTY HERETO ACKNOWLEDGES AND AGREES THAT ANY CONTROVERSY WHICH MAY ARISE UNDER
            THIS AGREEMENT IS LIKELY TO INVOLVE COMPLICATED AND DIFFICULT ISSUES, AND THEREFORE IT
            HEREBY IRREVOCABLY AND UNCONDITIONALLY WAIVES ANY RIGHT IT MAY HAVE TO A TRIAL BY JURY
            IN RESPECT OF ANY LITIGATION DIRECTLY OR INDIRECTLY ARISING OUT OF OR RELATING TO THIS
            AGREEMENT OR THE TRANSACTIONS CONTEMPLATED HEREBY. EACH PARTY TO THIS AGREEMENT
            CERTIFIES AND ACKNOWLEDGES THAT (A) NO REPRESENTATIVE, AGENT OR ATTORNEY OF ANY OTHER
            PARTY HAS REPRESENTED, EXPRESSLY OR OTHERWISE, THAT SUCH OTHER PARTY WOULD NOT, IN THE
            EVENT OF LITIGATION, SEEK TO ENFORCE EITHER OF SUCH WAIVERS; (B) IT UNDERSTANDS AND HAS
            CONSIDERED THE IMPLICATIONS OF SUCH WAIVERS; (C) IT MAKES SUCH WAIVERS VOLUNTARILY; AND
            (D) IT HAS BEEN INDUCED TO ENTER INTO THIS AGREEMENT BY, AMONG OTHER THINGS, THE MUTUAL
            WAIVERS AND CERTIFICATIONS IN THIS CLAUSE 16.11.
          </p>

          <h3>16.12 Arbitration</h3>

          <p>
            All disputes of the Parties arising out of or relating to this Agreement (including any
            determination of the scope or applicability of this agreement to arbitrate) (each, a “
            <a href="#dispite-dfn">Dispute</a>”) will be resolved in accordance with the procedures
            specified in this clause 16.12, which will be the sole and exclusive procedures for the
            resolution of any such Dispute. The Parties intend that these provisions will be valid,
            binding, enforceable and irrevocable and will survive any termination of this Agreement.
            Any Dispute will be submitted to final and binding arbitration with the Judicial
            Arbitration and Mediation Service in Los Angeles, California before a single arbitrator.
            The arbitrator shall apply the substantive laws of the State of California when
            resolving any Dispute, without regard for any choice or conflict of laws rule or
            principle that would result in the application of the substantive laws of any other
            jurisdiction. The parties to any such arbitration proceeding may obtain discovery in aid
            of the arbitration to the fullest extent permitted by law. All discovery disputes shall
            be resolved by the arbitrator. The arbitrator shall have the authority to grant any
            equitable and legal remedies that would be available in any judicial proceeding
            instituted in the state and federal courts in California to resolve a disputed matter.
            The substantially prevailing Party in such Dispute shall be entitled to reimbursement of
            such Party’s reasonable attorneys’ fees and costs (including expert fees), as well as
            such administrative fees in connection with such arbitration, and all costs and expenses
            of the arbitrator. Any dispute as to the reasonableness of any fee or cost will be
            resolved by the arbitrator. Except as may be necessary to enter judgment upon the award
            or to the extent required by applicable law, all claims, defenses and proceedings
            (including the existence of a controversy and the fact that there is an arbitration
            proceeding) will be treated in a confidential manner by the arbitrator and all those
            involved in the proceeding. Any controversy relating to the arbitration presented to a
            court will be filed under seal to the extent permitted by law. An arbitration award
            rendered pursuant to this clause 16.12 will be final and binding on the Parties and may
            be submitted to a court of competent jurisdiction for entry of a judgment thereon,
            absent fraud or manifest error. Notwithstanding the Parties’ agreement to submit all
            Disputes to final and binding arbitration and the right of the arbitrator to grant any
            equitable remedies as provided in this clause 16.12, the Parties shall have the right to
            seek and obtain temporary or preliminary injunctive relief or specific performance in
            any court of competent jurisdiction as set forth in clause 6.10. Such courts shall have
            authority to, among other things, grant temporary or provisional injunctive relief (with
            such relief effective until the arbitrator has rendered a final award) in order to
            protect any Party’s rights under this Agreement or otherwise.
          </p>

          <h3>16.13 Disclaimer of Warranties and Limitation of Liability</h3>

          <p>
            THE SERVICES, INCLUDING THE CONTENT AND ALL INFORMATION, MATERIALS, PRODUCTS AND
            SERVICES INCLUDED ON OR OTHERWISE MADE AVAILABLE THROUGH THE SERVICES, ARE PROVIDED BY
            US ON AN “AS IS” AND “AS AVAILABLE” BASIS, UNLESS OTHERWISE SPECIFIED IN WRITING. WE
            MAKE NO REPRESENTATIONS OR WARRANTIES OF ANY KIND, EXPRESS OR IMPLIED, AS TO THE
            OPERATION OF THE SERVICES OR THE CONTENT, INFORMATION, MATERIALS, PRODUCTS OR SERVICES
            INCLUDED ON OR OTHERWISE MADE AVAILABLE THROUGH THE SERVICES, UNLESS OTHERWISE SPECIFIED
            IN WRITING. YOU EXPRESSLY AGREE THAT YOUR USE OF THE SERVICES IS AT YOUR SOLE RISK. TO
            THE FULL EXTENT PERMISSIBLE BY APPLICABLE LAW, WE DISCLAIM ALL WARRANTIES, EXPRESS OR
            IMPLIED, INCLUDING, BUT NOT LIMITED TO, IMPLIED WARRANTIES OF MERCHANTABILITY AND
            FITNESS FOR A PARTICULAR PURPOSE. WE DO NOT WARRANT THAT THE SERVICES, THE CONTENT,
            INFORMATION, MATERIALS, PRODUCTS OR SERVICES INCLUDED ON OR OTHERWISE MADE AVAILABLE
            THROUGH THE SERVICES IS FREE OF VIRUSES OR OTHER HARMFUL COMPONENTS. IN NO EVENT SHALL
            WE OR ANY OF OUR AFFILIATES, SUPPLIERS OR THEIR RESPECTIVE MEMBERS, DIRECTORS, OFFICERS,
            EMPLOYEES, SHAREHOLDERS, PARTNERS OR AGENTS BE LIABLE FOR ANY INCIDENTAL, INDIRECT,
            PUNITIVE, EXEMPLARY, OR CONSEQUENTIAL DAMAGES WHATSOEVER (INCLUDING, WITHOUT LIMITATION,
            DAMAGES FOR LOSS OF PROFITS, BUSINESS INTERRUPTION, LOSS OF BUSINESS INFORMATION, OR ANY
            OTHER PECUNIARY LOSS) ARISING FROM THE USE OF THE SERVICES OR FROM THE CONTENT,
            INFORMATION, MATERIALS, PRODUCTS OR SERVICES INCLUDED ON OR OTHERWISE MADE AVAILABLE
            THROUGH THE SERVICES, WHETHER THE DAMAGES ARISE FROM USE OR MISUSE OR RELIANCE OR
            INABILITY TO USE THE SERVICES OR ANY OF THE CONTENT, INFORMATION, MATERIALS, PRODUCTS OR
            SERVICES INCLUDED ON OR OTHERWISE MADE AVAILABLE THROUGH THE SERVICES, OR WHETHER THEY
            ARISE FROM THE INTERRUPTION, SUSPENSION, MODIFICATION, ALTERATION, OR TERMINATION OF ANY
            SERVICES OR ANY CONTENT, INFORMATION, MATERIALS, PRODUCTS OR SERVICES INCLUDED ON OR
            OTHERWISE MADE AVAILABLE THROUGH THE SERVICES. IN NO EVENT SHALL OUR TOTAL MAXIMUM
            AGGREGATE LIABILITY UNDER THESE TERMS EXCEED THE AMOUNT ACTUALLY PAID, IF ANY, TO US BY
            THE INJURED PARTY. IN ANY JURISDICTION THAT DOES NOT ALLOW FOR THE LIMITATION OR
            EXCLUSION OF LIABILITY FOR INCIDENTAL OR CONSEQUENTIAL DAMAGES OR FOR A TOTAL MAXIMUM
            AMOUNT, OUR LIABILITY OR LIABILITY OF ANY OF OUR MEMBERS, DIRECTORS, OFFICERS,
            EMPLOYEES, SHAREHOLDERS, PARTNERS, AFFILIATES, AGENTS, SUPPLIERS, CUSTOMERS OR LICENSEES
            SHALL BE LIMITED TO THE MAXIMUM EXTENT PERMITTED BY LAW.
          </p>

          <h3>16.14 Embedded YouTube usage in Stile</h3>
          <p>
            Use of the YouTube widget to search for and display videos from YouTube embedded in
            Stile is subject to the YouTube Terms of Service, https://www.youtube.com/t/terms.
          </p>

          <Divider />

          <p>
            <em>Last updated: March 2024</em>
          </p>
        </Prose>
      </Section>

      <CtaBlock />
    </PageLayout>
  );
}

export default TermsPage;
